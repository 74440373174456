<template>
    <div>
        <div class="container">
            <TableMain
                @update="updateFilms"
                :items="filmsList"
                :header-columns='columns'
                :fields="fields"
                @getItems="getFilmsList"
                :valuesFromRoute="true"
                :itemsMeta="filmsMeta"
                :preloader="preloader"
            />
        </div>
    </div>
</template>

<script>
import Navbar from "./ui/Navbar";
import TableMain from "./ui/tables/TableMain";

export default {
    name: "FilmsTable",
    components:{
        TableMain,
        Navbar,
    },
    data () {
        return {
            fields: [
                {by: 'gov_register_number', type: "search"},
                {by: 'name', type: "search"},
                {by: 'distributorName', type: "select", options: []},
                {by: 'date', type: "data", isActive: false},
            ],
            optionsForSelect: [],
            filmsMeta: [],
            preloader: true,
            columns: [
                {
                    id: 1,
                    label: this.$t('Number'),
                    sort: false,
                    attribute: "gov_register_number",
                },
                {
                    id: 2,
                    label: this.$t('Name'),
                    sort: false,
                    attribute: "name",
                },
                {
                    id: 3,
                    label: this.$t('Distributor'),
                    sort: false,
                    attribute: "distributorName",
                },
                {
                    id: 4,
                    label: this.$t('Date'),
                    sort: false,
                    attribute: "date",
                },
            ],
            filmsList: [],
            distributorList: []
        }
    },
    watch: {
        optionsForSelect(){
            this.fields = [
                {by: 'gov_register_number', type: "search"},
                {by: 'name', type: "search"},
                {by: 'distributor_id', type: "select", options: this.optionsForSelect},
                {by: 'date', type: "data"},
            ]
        }
    },
    methods:{
        async getFilmsList(query = null){
            if (!query) {
                if (Object.keys(this.$route.query).length == 0) {
                    this.$router.push({ query: Object.assign({}, {page: 1, sort_by: 'id'}) });
                }
                query = this.$route.query
            }
            let resp = await this.api.cinema.getFilmsList(query)
            this.filmsList = resp.data
            this.filmsMeta = resp.meta

            this.filmsList.forEach(film => {
                if (film.distributor){
                    film.distributorName = film.distributor.name
                }
                if (film.date){
                    film.date = this.helper.getDate(film.date)
                }
            })
            this.preloader = false

        },
        updateFilms(id){
            this.$emit('updateFilms', id)
        },
        async getDistributors() {
            let resp = await this.api.distributor.getDists()
            resp.data.forEach(distributor => {
                this.optionsForSelect.push(
                    {label: distributor.name, id: distributor.id}
                )
            })
        }
    },
    created() {
        this.getFilmsList()
        this.getDistributors()
    }
}
</script>

<style scoped lang="scss">
    ::v-deep{
        th p{
            text-transform: uppercase;
        }

    }
</style>
