<template>
    <div class="films-container">
        <div class="container">
            <Breads :links="breadLinks"/>
            <PageHeader :title="$t('Movies')" :button-caption="$t('Add movie')" @pageHeaderButtonClick="openModal"/>
        </div>
        <FilmsTable ref="filmsTable" @updateFilms="updateFilms"/>
        <ModalComponent :show="showModal" @close='closeModal' :ask-before-leave="askBeforeLeave">
            <div class="modal-film-container">
                <form class="film-form" @submit.prevent="submitForm" ref="formFilms">
                    <div class="form-title">
                        {{ title }}
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <InputComponent
                                class="input-item"
                                name="gov_register_number"
                                :showLabel='true'
                                :placeholder="$t('State registration number')"
                                :required="true"
                                :error="errors.gov_register_number ? errors.gov_register_number : ''"
                                v-model="dataForEdit.gov_register_number"
                            />
                            <InputComponent
                                class="input-item name"
                                name="name"
                                :showLabel='true'
                                :placeholder="$t('Movie title')"
                                :required="true"
                                :error="errors.name ? errors.name : ''"
                                v-model="dataForEdit.name"
                            />
                            <InputComponent
                                class="input-item production"
                                name="production"
                                :showLabel='true'
                                :placeholder="$t('Production')"
                                :error="errors.production ? errors.production : ''"
                                v-model="dataForEdit.production"
                            />
                            <InputComponent
                                class="input-item written_by"
                                name="written_by"
                                :showLabel='true'
                                :placeholder="$t('Scriptwriter')"
                                :error="errors.written_by ? errors.written_by : ''"
                                v-model="dataForEdit.written_by"
                            />
                            <InputComponent
                                class="input-item main_rights"
                                name="main_rights"
                                :showLabel='true'
                                :placeholder="$t('Relevant rights to the film')"
                                :error="errors.main_rights ? errors.main_rights : ''"
                                v-model="dataForEdit.main_rights"
                            />
                            <SelectComponent
                                class="input-item distributor_id"
                                name="distributor_id"
                                :options="selectDistributor"
                                :showLabel='true'
                                :required="true"
                                :placeholder="$t('On the territory of Ukraine belong')"
                                :error="errors.distributor_id ? errors.distributor_id : ''"
                                v-model="dataForEdit.distributor_id"
                            />
                            <div class="datepicker-container">
                                <DatePickerComponent
                                    class="input-item term_of_rights"
                                    name="term_of_rights"
                                    :showLabel='true'
                                    :placeholder="$t('Validity of rights')"
                                    :error="errors.term_of_rights ? errors.term_of_rights : ''"
                                    v-model="dataForEdit.term_of_rights"
                                />
                                <DatePickerComponent
                                    class="input-item date"
                                    name="date"
                                    :showLabel='true'
                                    :placeholder="$t('Date')"
                                    :error="errors.date ? errors.date : ''"
                                    v-model="dataForEdit.date"
                                />
                            </div>
                            <InputComponent
                                class="input-item decision_commission"
                                name="decision_commission"
                                :showLabel='true'
                                :placeholder="$t('Decision of the expert commission')"
                                :error="errors.decision_commission ? errors.decision_commission : ''"
                                v-model="dataForEdit.decision_commission"
                            />
                        </div>
                        <div class="col-md-6 col-12">
                            <TextareaComponent
                                class="input-item description textarea"
                                name="description"
                                :showLabel='true'
                                :placeholder="$t('Description of the film')"
                                :resize="true"
                                :error="errors.description ? errors.description : ''"
                                v-model="dataForEdit.description"
                            />
                            <InputComponent
                                class="input-item voice_type"
                                name="voice_type"
                                :showLabel='true'
                                :placeholder="$t('Translation')"
                                :error="errors.voice_type ? errors.voice_type : ''"
                                v-model="dataForEdit.voice_type"
                            />
                            <InputComponent
                                class="input-item director"
                                name="director"
                                :showLabel='true'
                                :placeholder="$t('Director-producer')"
                                :error="errors.director ? errors.director : ''"
                                v-model="dataForEdit.director"
                            />
                            <InputComponent
                                class="input-item"
                                name="main_distributor"
                                :showLabel='true'
                                :placeholder="$t('Owned (transferred)')"
                                :error="errors.main_distributor ? errors.main_distributor : ''"
                                v-model="dataForEdit.main_distributor"
                            />
                            <InputComponent
                                class="input-item"
                                name="exercising_rights"
                                :showLabel='true'
                                :placeholder="$t('The method of exercising the rights to the film')"
                                :error="errors.exercising_rights ? errors.exercising_rights : ''"
                                v-model="dataForEdit.exercising_rights"
                            />
                            <InputComponent
                                class="input-item"
                                name="index_of_condition"
                                :showLabel='true'
                                :placeholder="$t('Index of conditions of distribution and demonstration')"
                                :error="errors.index_of_condition ? errors.index_of_condition : ''"
                                v-model="dataForEdit.index_of_condition"
                            />
                            <SelectComponent
                                class="input-item production-type"
                                name="production_type"
                                :options="selectProductionType"
                                :showLabel='true'
                                :placeholder="$t('Type of production')"
                                :error="errors.production_type ? errors.production_type : ''"
                                v-model="dataForEdit.production_type"
                                :required="true"
                            />
                        </div>
                    </div>
                    <div class="btn-container">
                        <button type="submit" class="btn-add">
                            {{ $t('Save') }}
                        </button>
                        <button type="button" class="btn-cancel" @click='closeModal'>
                            {{ $t('Cancel') }}
                        </button>
                    </div>
                </form>
            </div>
        </ModalComponent>
    </div>
</template>

<script>
import Navbar from "../components/ui/Navbar";
import FilmsTable from "../components/FilmsTable";
import ModalComponent from "../components/ui/ModalComponent";
import InputComponent from "../components/ui/forms/InputComponent";
import SelectComponent from "../components/ui/forms/SelectComponent";
import TextareaComponent from "../components/ui/forms/TextareaComponent";
import DatePickerComponent from "../components/ui/forms/DatePickerComponent";
import Breads from "../components/Breads";
import PageHeader from "../components/PageHeader";

export default {
    name: "Films",
    components: {
        FilmsTable,
        Navbar,
        ModalComponent,
        InputComponent,
        SelectComponent,
        TextareaComponent,
        DatePickerComponent,
        Breads,
        PageHeader
    },
    watch: {
        showModal() {
            this.askBeforeLeave = false
            if (!this.formForEdit) {
                this.title = this.$t('Add a movie')
            } else {
                this.title = this.$t('Edit movie')
            }
        },
        dataForEdit: {
            handler: function(){
                this.askBeforeLeave = true
            },
            deep: true
        }
    },
    data() {
        return {
            showModal: false,
            errors: {},
            idForEdit: null,
            formForEdit: false,
            dataForEdit: {
                gov_register_number: ''
            },
            distributorList: {},
            askBeforeLeave: false,
            selectDistributor: [],
            selectProductionType: [
                {id: 1, label: this.$t('Foreign')},
                {id: 2, label: this.$t('Ukrainian')},
            ],
            title: '',
            date: '',
            inProgress: false,
            breadLinks: [
                {
                    label: this.$t('Movies'),
                    to: {name: 'Films'}
                }
            ],
        }
    },
    methods: {
        async submitForm() {
            let resp;

            if (this.dataForEdit.production_type === 1){
                this.dataForEdit.production_type = 'foreign'
            } else if (this.dataForEdit.production_type === 2){
                this.dataForEdit.production_type = 'ukraine'
            }

            if (!this.formForEdit && !this.inProgress) {

                this.inProgress = true
                resp = await this.api.cinema.sentNewFilm(this.dataForEdit).catch(this.handleError)
                this.inProgress = false

            } else if (!this.inProgress) {

                this.inProgress = true
                resp = await this.api.cinema.updateFilm(this.idForEdit, this.dataForEdit).catch(this.handleError)
                this.inProgress = false
            }

            if (resp) {
                this.$refs.filmsTable.getFilmsList().catch(this.handleError)
                this.showModal = false
                this.errors = {}
                if (this.formForEdit) {
                    this.toast.success(this.$t('Movie updated successfully'))
                } else {
                    this.toast.success(this.$t("Movie successfully added"))
                }
            }
        },
        handleError(resp) {
            this.errors = {}
            if (resp.statusCode === 422) {
                this.errors = resp.messages
            }
        },
        closeModal() {
            this.showModal = false
            this.errors = {}
            this.dataForEdit = {}
        },
        async updateFilms(id) {
            this.idForEdit = id
            this.formForEdit = true
            this.showModal = true
            this.dataForEdit.date = null

            let resp = await this.api.cinema.getFilmsId(id).catch(this.handleError)

            this.dataForEdit = resp.data
            if (this.dataForEdit.date) {
                this.dataForEdit.date = this.helper.getDate(this.dataForEdit.date)
            }
            if (this.dataForEdit.term_of_rights) {
                this.dataForEdit.term_of_rights = this.helper.getDate(this.dataForEdit.term_of_rights)
            }
            setTimeout(() => this.askBeforeLeave = false, 500)

            if (this.dataForEdit.production_type === 'foreign'){
                this.dataForEdit.production_type = 1
            } else if (this.dataForEdit.production_type === 'ukraine'){
                this.dataForEdit.production_type = 2
            }
        },
        openModal() {
            this.errors = {}
            this.showModal = true
            this.dataForEdit = {}
            this.formForEdit = false
            this.askBeforeLeave = false
            setTimeout(() => this.askBeforeLeave = false, 500)
        },
        async getDists() {
            let resp = await this.api.distributor.getDists({no_pagination: true})

            this.distributorList = resp.data

            this.distributorList.forEach(dist => {
                this.selectDistributor.push({
                    id: dist.id,
                    label: dist.name
                })
            })
        }
    },
    created() {
        this.getDists()
    }
}
</script>

<style scoped lang="scss">
.row-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    .film-title {
        font-size: 30px;
        font-weight: 600;
        color: #141213;
    }

    .btn-add-film {
        width: 195px;
        height: 45px;
        border-radius: 2px;
        box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
        background-color: $color_darkblue;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 2px;
        color: $color_lightpink;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
    }
}

.films-container {

    .form-title {
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #141213;
        margin-bottom: 10px;
    }

    .input-item {
        margin-top: 24px;
    }

    .datepicker-container{
        display: flex;
        width: 100%;
        justify-content: space-between;
        .input-item{
            width: 47%;
        }
    }

    .btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

        .btn-add {
            width: 173px;
            height: 45px;
            border-radius: 2px;
            background-color: $color_darkblue;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 900;
            letter-spacing: 2px;
            border: none;
            margin-right: 15px;
            color: white;
            &:hover{
                box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
                color: $color_lightpink;
            }
        }

        .btn-cancel {
            width: 172px;
            height: 45px;
            border-radius: 2px;
            border: solid 1px $color_darkblue;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 900;
            letter-spacing: 2px;
            color: #141213;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
        }
    }
    .production-type{
        margin-top: 20px;
    }
}



::v-deep {
    .form-group__label {
        margin-bottom: 5px;
    }

    .textarea .form-group__textarea{
        min-height: 143px;
    }

    .modal__content {
        @media screen and (min-width: $xl) {
            width: 1310px;
        }
    }

    .right-inputs-column textarea {
        resize: none;
        height: 143px;
    }

    .form-group__input, .form-group__textarea, .mx-input-wrapper input {
        background-color: #F8FAFF;
    }

    .modal {
        overflow-y: scroll;
    }

    //.form-group .form-group__error, .select-element__error{
    //    position: absolute;
    //    right: auto;
    //    bottom: auto;
    //}
}
</style>
