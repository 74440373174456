<template>
  <div class="form-group">
      <label v-if="showLabel" class="form-group__label">{{ placeholder }}</label>
      <textarea
          :value="value"
          :name="name"
          class="form-control form-group__textarea"
          v-on:input="$emit('input', $event.target.value)"
          :placeholder="[showLabel ? '' : placeholder]"
          :class="{'is-invalid' : error.length}"
      ></textarea>
      <span v-if="error" class="form-group__error">{{ error }}</span>
  </div>
</template>

<script>
export default {
    name: "TextareaComponent",
    props: {
      name: {
          type: String,
          required: true
      },
      value: {
          required: false,
      },
      placeholder: {
          type: String,
          required: false
      },
      error: {
          required: false,
          default: false
      },
      showLabel: {
          type: Boolean,
          default: false
      }
  }
}
</script>

<style lang="scss" scoped>

  .form-group {
      &__label {
          margin-bottom: 8px;
          color: $color_gray;
      }

      &__textarea {
          border: solid 1px $color_lightgray;
          border-radius: 2px;
          color: $color_black;
          background-color: $color_light;
          padding: 10px 15px;
          font-size: 15px;
          font-family: $ff-primary;
          width: 100%;
          box-sizing: border-box;

          &::placeholder {
              color: $color_black;
          }

          &.is-invalid {
              border-color: $color_red;
          }

          &[disabled] {
              color: $color_gray;
              cursor: initial;
              border: solid 1px $color_light;

              &::placeholder {
                  color: $color_gray;
              }
          }
      }

      &__error {
            //font-size: 13px;
            //color: $color_red;
            //position: absolute;
            //right: 0;
            //bottom: -18px;
          font-size: 13px;
          color: #FF496D;
          position: absolute;
          right: 0;
          bottom: auto;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
      }
  }
</style>
